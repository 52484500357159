import { Form, Formik } from 'formik'
import { BackLink, Button, Heading } from 'govuk-react'
import React, { useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import { KeyEventDateType } from '../../graphql/generated/schema'
import { FieldTheme, KeyEventsModalFormData } from '../../types'
import {
  addLeadingZeroToDigit,
  formatDateStringWithLeadingZeroDigits
} from '../../utils/dateHelper'
import { AutocompleteField } from '../AutocompleteField'
import { DateField } from '../DateField'
import { RadioField } from '../RadioField'
import { TextAreaField } from '../TextAreaField'
import styles from './KeyEventsForm.module.scss'
import {
  getFormValues,
  getPageOptions,
  getPageOptionsTo,
  setFormValuesToSubmit
} from './utils/helper'
import { schema } from './validation/schema'

export interface KeyEventsFormProps {
  /** Handle back button click */
  onBackButtonClick: () => void
  /** Handle add Key Event button click */
  handleKeyEventUpsert: (data: KeyEventsModalFormData) => void

  pageCount: number

  initialFormValues: KeyEventsModalFormData
}

/** KeyEventsModal description.*/
export const KeyEventsForm: React.FunctionComponent<KeyEventsFormProps> = ({
  onBackButtonClick,
  handleKeyEventUpsert,
  initialFormValues,
  pageCount
}) => {
  const handleSubmit = async (values: KeyEventsModalFormData) => {
    handleKeyEventUpsert(values)
  }

  const [pageFrom, setPageFrom] = useState(1)

  const [pageFromOptions, setPageFromOptions] = useState<
    { value: string; label: string }[]
  >([])

  const [pageToOptions, setPageToOptions] = useState<
    { value: string; label: string }[]
  >([])

  useEffect(() => {
    setPageFromOptions(getPageOptions(pageCount))
    setPageToOptions(getPageOptionsTo(pageCount, pageFrom))
  }, [pageFrom, pageCount])

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    transform: { 'translateX(0%)': 'translateX(-100%)' },
    config: { duration: 800 }
  })

  return (
    <animated.div
      style={props}
      data-testid={'main key events form container'}
      className={styles.Container}
    >
      <Formik
        initialValues={getFormValues(initialFormValues)}
        onSubmit={(values) => {
          handleSubmit(setFormValuesToSubmit(values))
        }}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, setFieldValue }) => {
          if (values.pageFrom !== undefined && values.pageTo === undefined) {
            setPageFrom(Number(values.pageFrom))
          }

          return (
            <div className={styles.Container}>
              <div className={styles.backlink}>
                <BackLink
                  href={''}
                  type="button"
                  onClick={(e) => {
                    // Prevent reload of create bundle route
                    e.preventDefault()
                    onBackButtonClick()
                  }}
                >
                  Back
                </BackLink>
              </div>
              <Form className={styles.formContainer}>
                <div className={styles.radioField}>
                  <RadioField
                    name="dateType"
                    label={<strong>Key event date</strong>}
                    radioOptions={[
                      {
                        label: 'Dated',
                        value: KeyEventDateType.Dated
                      },
                      {
                        label: 'Undated',
                        value: KeyEventDateType.Undated
                      },
                      {
                        label: 'Various',
                        value: KeyEventDateType.MultipleDates
                      }
                    ]}
                    onChange={async (event) => {
                      if (event.target.value === KeyEventDateType.Dated) {
                        values.occuredAt = initialFormValues.occuredAt ?? '--'
                      } else {
                        values.occuredAt = null
                      }
                    }}
                  />

                  {values.dateType === KeyEventDateType.Dated && (
                    <DateField
                      dateName="occuredAt"
                      fieldTheme={FieldTheme.WHITE}
                      onBlur={async (e) => {
                        if (e.target.value) {
                          e.target.value = addLeadingZeroToDigit(e.target.value)
                          values.occuredAt = values.occuredAt
                            ? formatDateStringWithLeadingZeroDigits(
                                values.occuredAt,
                                '-'
                              )
                            : values.occuredAt
                        }
                      }}
                    />
                  )}
                </div>
                <div className={styles.textField}>
                  <TextAreaField
                    name="body"
                    label="Details"
                    hint=""
                  ></TextAreaField>
                </div>

                <br></br>

                <Heading
                  size="SMALL"
                  style={{ padding: '0rem 0rem 0rem 0.9375rem' }}
                  font-weight="bold"
                >
                  Pages
                </Heading>

                <div className={styles.tagsContainer}>
                  <div className={styles.pagesContainer} key={'tagKey'}>
                    <AutocompleteField
                      isCreatableSelect={false}
                      label="From"
                      name="pageFrom"
                      options={pageFromOptions}
                      placeholderText={'Select'}
                      onChange={(value) => {
                        setPageFrom(Number(value))
                        setFieldValue('pageTo', value)
                      }}
                    />
                  </div>

                  <div className={styles.pagesContainer}>
                    <AutocompleteField
                      label="To"
                      name="pageTo"
                      options={values.pageFrom ? pageToOptions : []}
                      placeholderText={'Select'}
                      isCreatableSelect={false}
                    />
                  </div>
                </div>
                {values.dateType !== KeyEventDateType.Dated && (
                  <hr className={styles.divider}></hr>
                )}

                <div className={styles.submitButton}>
                  <Button margin={2} type="submit">
                    {values.id === '' ? 'Add event' : 'Save event'}
                  </Button>
                </div>
              </Form>
            </div>
          )
        }}
      </Formik>
    </animated.div>
  )
}
