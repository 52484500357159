import { ApolloProvider } from '@apollo/client'
import { Amplify } from 'aws-amplify'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { amplifyConfig } from './amplify-config'
import { ServiceUnavailable } from './components/ServiceUnavailable'
import { AuthProvider } from './context/AuthContext'
import { client } from './graphql/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { AccessibilityStatement } from './routes/AccessibilityStatement'
import { App } from './routes/App'
import { Bundle } from './routes/Bundle'
import { BundleComplete } from './routes/BundleComplete'
import { BundleEvidence } from './routes/BundleEvidence'
import { BundlePreview } from './routes/BundlePreview'
import { CreateBundle } from './routes/CreateBundle'
import { Dashboard } from './routes/Dashboard'
import { PageNotFound } from './routes/PageNotFound'
import { Profile } from './routes/Profile'
import { ProtectedRoute } from './routes/ProtectedRoute'
import { SignIn } from './routes/SignIn'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

Amplify.configure(amplifyConfig)

root.render(
  <ServiceUnavailable>
    <AuthProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<App />}>
                <Route index element={<Dashboard />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/bundles/create" element={<CreateBundle />} />
                <Route path="/bundles/:bundleId" element={<Bundle />}>
                  <Route
                    path="/bundles/:bundleId/evidence/:evidenceId"
                    element={<BundleEvidence />}
                  />
                </Route>
                <Route
                  path="/bundles/:bundleId/preview"
                  element={<BundlePreview />}
                />
                <Route
                  path="/bundles/:bundleId/complete"
                  element={<BundleComplete />}
                />
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Route>
            <Route path="/sign-in" element={<App />}>
              <Route index element={<SignIn />} />
            </Route>
            <Route path="/help/accessibility-statement" element={<App />}>
              <Route index element={<AccessibilityStatement />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    </AuthProvider>
  </ServiceUnavailable>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
