import * as dateFns from 'date-fns'
import { enGB } from 'date-fns/locale'
import { Form, Formik, FormikHelpers } from 'formik'
import { BackLink, Button, Heading, Paragraph, Spinner } from 'govuk-react'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import {
  DrsDocumentIdentifierType,
  useValidateDrsSearchLazyQuery
} from '../../graphql/generated/schema'
import { CreateBundleFormData } from '../../types'
import {
  addLeadingZeroToDigit,
  formatDateStringWithLeadingZeroDigits
} from '../../utils/dateHelper'
import { attachFocusToFirstFormElementError } from '../../utils/formikHelper'
import { DateField } from '../DateField'
import { ErrorSummaryRetry } from '../ErrorSummaryRetry'
const CURRENT_DATE = new Date()

const formErrorMessages = {
  unknownSearchIdentifier:
    'The search identifier you have provided does not exist in DRS',
  drsUnavailable: 'DRS Search Service is unavailable',
  noDocuments: 'There are no valid documents in DRS for this search identifier',
  requiredDate: 'Date cannot be blank',
  invalidDate: 'Must be a valid date',
  pastDate: 'Date must be in the past',
  startDateValidation: 'Start date cannot be after the end date'
}

export interface DrsDateRangeSearchFormProps {
  /** Initial form data. */
  data: CreateBundleFormData
  /** Function to invoke when going back to the previous step in the form. */
  prev: (data: CreateBundleFormData, currentStep?: number) => void
  /** Function to invoke when submitting the form. */
  next: (
    data: CreateBundleFormData,
    final?: boolean,
    currentStep?: number
  ) => void
  /** Key if part of multi-step form */
  key?: number
  /** Flag to toggle loading state. */
  isSubmitLoading?: boolean
}

/** Formik form allowing user to enter a Date range value for the DRS Search.
 * This data will then be used download documents from DRS.
 */
export const DrsDateRangeSearchForm: React.FunctionComponent<
  DrsDateRangeSearchFormProps
> = ({ data, prev, next, isSubmitLoading = false }) => {
  const [
    validateDrsSearchQuery,
    { error: validateDrsSearchError, loading: validateDrsSearchLoading }
  ] = useValidateDrsSearchLazyQuery()
  const [drsDocumentFromDateFilterValue, setDrsDocumentFromDateFilterValue] =
    useState<string>(data.drsFromDate as string)
  const [drsDocumentToDateFilterValue, setDrsDocumentToDateFilterValue] =
    useState<string>(data.drsToDate as string)

  const validateDrsSearchValue = async (
    createError: (params?: yup.CreateErrorOptions) => yup.ValidationError,
    path: string
  ) => {
    const {
      data: validateDrsSearchData,
      error: validateDrsSearchValidationError
    } = await validateDrsSearchQuery({
      variables: {
        validateDrsSearchInput: {
          businessAreaId: data.businessArea,
          appealTypeId: data.appealType ? data.appealType : null,
          drsFromDate: drsDocumentFromDateFilterValue,
          drsToDate: drsDocumentToDateFilterValue,
          drsDocumentIdentifierValue:
            data.nationalInsuranceNumber ||
            data.claimReferenceNumber ||
            data.customerReferenceNumber,
          drsDocumentIdentifierType:
            data.drsSearchIdentifier as DrsDocumentIdentifierType
        }
      }
    })

    if (validateDrsSearchData && !validateDrsSearchValidationError) {
      const docCount = validateDrsSearchData.validateDrsSearch?.documentCount

      if (docCount === 0) {
        return createError({
          path,
          message: formErrorMessages.noDocuments
        })
      } else {
        return true
      }
    } else if (validateDrsSearchValidationError) {
      const errorCode =
        validateDrsSearchValidationError.graphQLErrors[0].extensions.code

      if (errorCode === 'DRS_DOCUMENT_IDENTIFIER_NOT_FOUND') {
        return createError({
          path,
          message: formErrorMessages.unknownSearchIdentifier
        })
      } else if (errorCode === 'DRS_SERVER_ERROR') {
        return createError({
          path,
          message: formErrorMessages.drsUnavailable
        })
      } else {
        return true
      }
    } else {
      return false
    }

    return true
  }

  // Parses date from string in format 'yyyy-mm-dd'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseDateString = (value: any, originalValue: any) => {
    const parsedDate: Date = dateFns.isDate(originalValue)
      ? originalValue
      : dateFns.parse(originalValue, 'yyyy-MM-dd', new Date(), {
          locale: enGB
        })

    return parsedDate
  }

  yup.addMethod(yup.string, 'isDrsFromDateValid', function () {
    return this.test('drsFromDate', function (value) {
      const { path, createError } = this

      if (value === '--') {
        return createError({
          path,
          message: formErrorMessages.requiredDate
        })
      }

      if (value) {
        const drsFromDate = parseDateString('', value)

        // // TypeError - invalid date (is not an instance of Date or date value is not a number)
        if (!dateFns.isValid(drsFromDate)) {
          return createError({
            path,
            message: formErrorMessages.invalidDate
          })
        }

        if (drsFromDate > CURRENT_DATE) {
          return createError({
            path,
            message: formErrorMessages.pastDate
          })
        }

        const drsToDate = parseDateString('', this.parent.drsToDate)

        if (dateFns.isValid(drsToDate) && drsFromDate > drsToDate) {
          return createError({
            path,
            message: formErrorMessages.startDateValidation
          })
        }

        if (dateFns.isValid(drsFromDate) && dateFns.isValid(drsToDate)) {
          return validateDrsSearchValue(createError, path)
        }
      }
      return true
    })
  })

  yup.addMethod(yup.string, 'isDrsToDateValid', function () {
    return this.test('drsToDate', function (value) {
      const { path, createError } = this

      if (value === '--') {
        return createError({
          path,
          message: formErrorMessages.requiredDate
        })
      }

      if (value) {
        const drsToDate = parseDateString('', value)

        // // TypeError - invalid date (is not an instance of Date or date value is not a number)
        if (!dateFns.isValid(drsToDate)) {
          return createError({
            path,
            message: formErrorMessages.invalidDate
          })
        }

        if (drsToDate > CURRENT_DATE) {
          return createError({
            path,
            message: formErrorMessages.pastDate
          })
        }

        const drsFromDate = parseDateString('', this.parent.drsFromDate)

        if (dateFns.isValid(drsFromDate) && drsFromDate > drsToDate) {
          return createError({
            path: 'drsFromDate',
            message: formErrorMessages.startDateValidation
          })
        }

        if (dateFns.isValid(drsFromDate) && dateFns.isValid(drsToDate)) {
          return validateDrsSearchValue(createError, path)
        }
      }
      return true
    })
  })

  const validationSchema = yup.object({
    drsFromDate: yup
      .string()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .isDrsFromDateValid(),
    drsToDate: yup
      .string()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .isDrsToDateValid(),
    drsSearch: yup.string().when(['drsFromDate', 'drsToDate'], {
      is: (drsFromDate: string | null, drsToDate: string | null) =>
        !!drsFromDate && !!drsToDate,
      then: yup.string()
    })
  })

  const handleSubmit = async (
    values: CreateBundleFormData,
    { setSubmitting }: FormikHelpers<CreateBundleFormData>
  ) => {
    setSubmitting(false)

    let isFinalFormStep = false
    let currentStep

    if (values.localUploadRequired === 'No') {
      isFinalFormStep = true
    }

    if (values.localUploadRequired === 'Yes') {
      currentStep = 3
    }

    next(values, isFinalFormStep, currentStep)
  }

  // Reset focus for accessibility screen readers
  useEffect(() => {
    document.getElementById('main-focus')?.focus()
  }, [])

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={data}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting, isValidating, errors }) => {
        if (isSubmitting && !isValidating && errors) {
          // If validation errors exist, set focus on first error element
          attachFocusToFirstFormElementError(errors)
        }
        return (
          <div>
            <div>
              <BackLink
                href={''}
                type="button"
                onClick={(e) => {
                  // Prevent reload of create bundle route
                  e.preventDefault()
                  prev(values, 3)
                }}
              >
                Back
              </BackLink>
            </div>

            <Heading size="XLARGE">Enter a date for your DRS search</Heading>
            {validateDrsSearchError &&
              validateDrsSearchError.message ===
                'Search Service unavailable' && (
                <>
                  <ErrorSummaryRetry
                    heading="There is a problem retrieving information from the service"
                    includeBorder={true}
                  />
                </>
              )}

            <Paragraph>
              The dates you enter here will return documents within this range.
              Please check the dates to ensure all relevant evidence for the
              appeal has been selected.
            </Paragraph>

            <Form name="date-range-form">
              <div>
                <DateField
                  dateName="drsFromDate"
                  label=""
                  hint="Include DRS documents starting from"
                  onBlur={async (e) => {
                    if (e.target.value) {
                      e.target.value = addLeadingZeroToDigit(e.target.value)
                      values.drsFromDate = values.drsFromDate
                        ? formatDateStringWithLeadingZeroDigits(
                            values.drsFromDate,
                            '-'
                          )
                        : values.drsFromDate
                      setDrsDocumentFromDateFilterValue(
                        values.drsFromDate as string
                      )
                    }
                  }}
                />
              </div>

              <div>
                <DateField
                  dateName="drsToDate"
                  label=""
                  hint="Up to"
                  onBlur={async (e) => {
                    if (e.target.value) {
                      e.target.value = addLeadingZeroToDigit(e.target.value)
                      values.drsToDate = values.drsToDate
                        ? formatDateStringWithLeadingZeroDigits(
                            values.drsToDate,
                            '-'
                          )
                        : values.drsToDate
                      setDrsDocumentToDateFilterValue(
                        values.drsToDate as string
                      )
                    }
                  }}
                />
              </div>

              <div>
                <Button
                  disabled={isSubmitLoading || validateDrsSearchLoading}
                  type="submit"
                >
                  Continue
                </Button>
                {isSubmitLoading || validateDrsSearchLoading ? (
                  <Spinner className="" width="25px" height="25px" />
                ) : null}
              </div>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}
