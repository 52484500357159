import { Footer as FooterGovUk } from 'govuk-react'
import React, { useEffect } from 'react'
import styles from './Footer.module.scss'

/** Footer from gov uk library styled to application requirements.*/
export const Footer: React.FunctionComponent = () => {
  useEffect(() => {
    const footerHiddenHeaderElement = document
      .getElementById('footer-div')
      ?.getElementsByTagName('h2')[0]

    if (footerHiddenHeaderElement) {
      footerHiddenHeaderElement.remove()
    }
  }, [])

  return (
    <div id={'footer-div'} className={styles.footer}>
      <FooterGovUk
        licence={false}
        meta={
          <FooterGovUk.MetaLinks heading="Support links">
            <FooterGovUk.Link href="/help/accessibility-statement">
              Accessibility statement
            </FooterGovUk.Link>
            <FooterGovUk.Link href="https://www.gov.uk/government/organisations/department-for-work-pensions/about/personal-information-charter">
              Privacy
            </FooterGovUk.Link>
          </FooterGovUk.MetaLinks>
        }
      ></FooterGovUk>
    </div>
  )
}
