import {
  BackLink,
  Heading,
  ListItem,
  OrderedList,
  Page,
  Paragraph,
  UnorderedList
} from 'govuk-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './AccessibilityStatement.module.scss'

export const AccessibilityStatement: React.FunctionComponent = () => {
  const navigate = useNavigate()

  const handleHomeClick = () => {
    navigate('/')
  }
  return (
    <>
      <Page.WidthContainer>
        <div className={styles.backLink}>
          <BackLink href={''} type="button" onClick={handleHomeClick}>
            Home
          </BackLink>
        </div>
        <Page.Main>
          <Heading size="XLARGE" style={{ marginBottom: '25px' }}>
            Accessibility statement for Bundle Builder
          </Heading>
          <Paragraph>
            This accessibility statement applies to the Bundle Builder site
            only.
          </Paragraph>
          <Paragraph>
            This site is run by the Garage (previously known as the Intelligent
            Automation Garage, or IAG), part of Digital Group’s Cross Boundary
            Team (CBT).
          </Paragraph>
          <Heading size="LARGE">How accessible this site is</Heading>
          <Paragraph>
            Parts of this site are not fully accessible. For example:
          </Paragraph>
          <UnorderedList>
            <ListItem>some images may not have alternative texts</ListItem>
            <ListItem>
              some horizontal scrolling is required when zoomed in 400%
            </ListItem>
          </UnorderedList>
          <Heading size="LARGE">Feedback and contact information</Heading>
          <Paragraph>
            If you find any problems not listed on this page or think we’re not
            meeting accessibility requirements, please raise a DWP Place
            incident.
          </Paragraph>
          <Heading size="LARGE">Enforcement procedure</Heading>
          <Paragraph>
            The Equality and Human Rights Commission (EHRC) is responsible for
            enforcing the Public Sector Bodies (Websites and Mobile
            Applications) (No. 2) Accessibility Regulations 2018 (the
            ‘accessibility regulations’).
          </Paragraph>

          <p>
            {' '}
            If you’re not happy with how we respond to your complaint,
            <a href="https://www.equalityadvisoryservice.com">
              contact the Equality Advisory and Support Service (EASS).
            </a>
          </p>
          <Heading size="LARGE">
            Technical information about this site’s accessibility
          </Heading>
          <Paragraph>
            The Garage is committed to making its websites accessible, in
            accordance with the Public Sector Bodies (Websites and Mobile
            Applications) (No. 2) Accessibility Regulations 2018.
          </Paragraph>
          <Heading size="LARGE">Compliance status</Heading>
          <p>
            This website is partially compliant with the{' '}
            <a href="https://www.w3.org/TR/WCAG22/">
              Web Content Accessibility Guidelines version 2.2
            </a>{' '}
            AA standard, due to the non-compliances listed below.
          </p>
          <Heading size="LARGE">Non-accessible content</Heading>
          <Paragraph>
            The content listed below is non-accessible for the following
            reasons.
          </Paragraph>
          <Heading size="LARGE">
            Non-compliance with the accessibility regulations
          </Heading>
          <OrderedList style={{ fontSize: '19px', fontWeight: 400 }}>
            <ListItem>
              Some images do not have alternative text. This fails WCAG 2.2
              success criterion 1.1.1 (Non-text Content).
            </ListItem>
            <ListItem>
              A missing form label and some names are not accessible. This fails
              WCAG 2.2 success criterion 1.3.1 (Info and Relationships).
            </ListItem>
            <ListItem>
              Some interactive controls are nested. This fails WCAG 2.2 success
              criterion 1.3.1 (Info and Relationships).
            </ListItem>
            <ListItem>
              Some text colours do not meet the minimum contrast ratio. This
              fails WCAG 2.2 success criterion 1.4.3 (Contrast (Minimum)).
            </ListItem>
            <ListItem>
              Some horizonal scrolling is required when the zoom is set to 400%.
              This fails WCAG 2.2 success criterion 1.4.10 (Reflow).
            </ListItem>
            <ListItem>
              One drop box is not fully visible when zoom is set to 400%. This
              fails WCAG 2.2 success criterion 1.4.10 (Reflow).
            </ListItem>
            <ListItem>
              Two pages do not have a title. This fails WCAG 2.2 success
              criterion 2.4.2 (Page Titled).
            </ListItem>
          </OrderedList>
          <Heading size="LARGE">
            What we’re doing to improve accessibility
          </Heading>
          <Paragraph>
            We are working towards fixing the content which fails to meet the
            WCAG 2.2 AA standard and will include improvements in future
            updates.
          </Paragraph>
          <Heading size="LARGE">
            Preparation of this accessibility statement
          </Heading>
          <Paragraph>
            This statement was prepared on 25 September 2024. It was last
            reviewed on 26 September 2024.
          </Paragraph>
          <Paragraph>
            This site was last tested on 9 September 2024 against WCAG 2.2 AA
            standard. This test was carried out by the Garage’s test team.
          </Paragraph>
        </Page.Main>
      </Page.WidthContainer>
    </>
  )
}
