import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: string
  DateTime: string
  UUID: string
  Void: any
}

export type AddLocalDocumentsToBundleInput = {
  bundleId: Scalars['UUID']
  localDocuments?: InputMaybe<Array<InputMaybe<LocalDocument>>>
}

export type AppealType = {
  __typename?: 'AppealType'
  id: Scalars['UUID']
  name: Scalars['String']
}

export type Bundle = {
  __typename?: 'Bundle'
  appealType?: Maybe<AppealType>
  author: User
  businessArea: BusinessArea
  createdAt: Scalars['DateTime']
  customerName: Scalars['String']
  documents: Array<Document>
  dossiers: Array<Dossier>
  drsDocumentIdentifier?: Maybe<Scalars['String']>
  drsDocumentIdentifierType?: Maybe<DrsDocumentIdentifierType>
  id: Scalars['UUID']
  isUploadBundleToDrs: Scalars['Boolean']
  keyEventStatus?: Maybe<KeyEventGenerationStatus>
  keyEventsCsvFileS3ObjectKey?: Maybe<Scalars['String']>
  keyEventsPdfFileS3ObjectKey?: Maybe<Scalars['String']>
  status: BundleStatus
  updatedAt: Scalars['DateTime']
}

export enum BundleStatus {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS'
}

export type BusinessArea = {
  __typename?: 'BusinessArea'
  appealTypes: Array<AppealType>
  id: Scalars['UUID']
  isDrsEnabled: Scalars['Boolean']
  name: Scalars['String']
}

export type CompleteBundleInput = {
  id: Scalars['UUID']
  uploadBundleToDrs: Scalars['Boolean']
}

export type CreateBundleInput = {
  appealTypeId?: InputMaybe<Scalars['UUID']>
  businessAreaId: Scalars['UUID']
  customerName: Scalars['String']
  drsDocumentIdentifierType?: InputMaybe<DrsDocumentIdentifierType>
  drsDocumentIdentifierValue?: InputMaybe<Scalars['String']>
  drsFromDateFilterValue?: InputMaybe<Scalars['Date']>
  drsToDateFilterValue?: InputMaybe<Scalars['Date']>
  localDocuments?: InputMaybe<Array<InputMaybe<LocalDocument>>>
}

export type CreateDossiersInput = {
  bundleId: Scalars['UUID']
}

export type CreateKeyEventInput = {
  body: Scalars['String']
  dateType?: InputMaybe<KeyEventDateType>
  documentId: Scalars['UUID']
  occuredAt?: InputMaybe<Scalars['Date']>
  pageFrom?: InputMaybe<Scalars['Int']>
  pageTo?: InputMaybe<Scalars['Int']>
}

export type DecisionType = {
  __typename?: 'DecisionType'
  id: Scalars['UUID']
  name: Scalars['String']
}

export type Document = {
  __typename?: 'Document'
  createdAt: Scalars['DateTime']
  date?: Maybe<Scalars['Date']>
  dateType?: Maybe<DocumentDateType>
  decisionType?: Maybe<DecisionType>
  documentAnalysisStatus: DocumentAnalysisStatus
  documentType?: Maybe<DocumentType>
  fileS3ObjectKey?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  initialPageCount?: Maybe<Scalars['Int']>
  keyEvents?: Maybe<Array<Maybe<KeyEvent>>>
  name?: Maybe<Scalars['String']>
  optimisedFileSizeInBytes?: Maybe<Scalars['Int']>
  sort?: Maybe<Scalars['Int']>
  status: DocumentStatus
  updatedAt: Scalars['DateTime']
}

export enum DocumentAnalysisStatus {
  Analysing = 'ANALYSING',
  Completed = 'COMPLETED',
  Error = 'ERROR',
  NotSupported = 'NOT_SUPPORTED',
  Pending = 'PENDING'
}

export enum DocumentDateType {
  Dated = 'DATED',
  MultipleDates = 'MULTIPLE_DATES',
  Undated = 'UNDATED'
}

export enum DocumentStatus {
  Corrupt = 'CORRUPT',
  Error = 'ERROR',
  Invalid = 'INVALID',
  InProgress = 'IN_PROGRESS',
  Loading = 'LOADING',
  NotReviewed = 'NOT_REVIEWED',
  Reviewed = 'REVIEWED'
}

export type DocumentType = {
  __typename?: 'DocumentType'
  decisionTypes?: Maybe<Array<DecisionType>>
  id: Scalars['UUID']
  isBlacklisted: Scalars['Boolean']
  name: Scalars['String']
  relatedDocumentNames?: Maybe<Array<Scalars['String']>>
}

export type Dossier = {
  __typename?: 'Dossier'
  completedAt?: Maybe<Scalars['DateTime']>
  createdAt: Scalars['DateTime']
  fileS3ObjectKey?: Maybe<Scalars['String']>
  fileSize?: Maybe<Scalars['Int']>
  hasUploadingFailed: Scalars['Boolean']
  id: Scalars['UUID']
  isUploaded: Scalars['Boolean']
  status: DossierStatus
  type: DossierType
  updatedAt: Scalars['DateTime']
}

export enum DossierStatus {
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Generating = 'GENERATING',
  Invalid = 'INVALID'
}

export enum DossierType {
  Harmful = 'HARMFUL',
  Redacted = 'REDACTED',
  RedactedHarmful = 'REDACTED_HARMFUL',
  Standard = 'STANDARD'
}

export enum DrsDocumentIdentifierType {
  ClaimReferenceNumber = 'CLAIM_REFERENCE_NUMBER',
  CustomerReferenceNumber = 'CUSTOMER_REFERENCE_NUMBER',
  Nino = 'NINO'
}

export type DrsSearchInput = {
  appealTypeId?: InputMaybe<Scalars['UUID']>
  businessAreaId: Scalars['UUID']
  drsDocumentIdentifierType: DrsDocumentIdentifierType
  drsDocumentIdentifierValue: Scalars['String']
  drsFromDate?: InputMaybe<Scalars['Date']>
  drsSearchType?: InputMaybe<DrsSearchType>
  drsToDate?: InputMaybe<Scalars['Date']>
}

export type DrsSearchResponse = {
  __typename?: 'DrsSearchResponse'
  documentCount: Scalars['Int']
}

export enum DrsSearchType {
  AllDocuments = 'ALL_DOCUMENTS',
  DateRange = 'DATE_RANGE'
}

export type GeographicRegion = {
  __typename?: 'GeographicRegion'
  id: Scalars['UUID']
  name: Scalars['String']
}

export type KeyEvent = {
  __typename?: 'KeyEvent'
  body: Scalars['String']
  createdAt: Scalars['DateTime']
  dateType?: Maybe<KeyEventDateType>
  documentId: Scalars['UUID']
  id: Scalars['UUID']
  occuredAt?: Maybe<Scalars['Date']>
  pageFrom?: Maybe<Scalars['Int']>
  pageTo?: Maybe<Scalars['Int']>
  sort: Scalars['Int']
  source: KeyEventSource
  status: KeyEventStatus
  updatedAt: Scalars['DateTime']
}

export enum KeyEventDateType {
  Dated = 'DATED',
  MultipleDates = 'MULTIPLE_DATES',
  Undated = 'UNDATED'
}

export enum KeyEventGenerationStatus {
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Generating = 'GENERATING',
  Pending = 'PENDING'
}

export enum KeyEventSource {
  System = 'SYSTEM',
  User = 'USER'
}

export enum KeyEventStatus {
  NotReviewed = 'NOT_REVIEWED',
  Reviewed = 'REVIEWED'
}

export type LocalDocument = {
  name: Scalars['String']
  temporaryFileS3ObjectKey: Scalars['String']
}

export type MoveDocumentAfterInput = {
  bundleId: Scalars['UUID']
  documentToMove: Scalars['UUID']
  documentToMoveAfter?: InputMaybe<Scalars['UUID']>
}

export type MoveKeyEventAfterInput = {
  documentId: Scalars['UUID']
  keyEventToMove: Scalars['UUID']
  keyEventToMoveAfter?: InputMaybe<Scalars['UUID']>
}

export type Mutation = {
  __typename?: 'Mutation'
  addLocalDocumentsToBundle: Bundle
  completeBundle: Bundle
  createBundle: Bundle
  createDossiers: Array<Dossier>
  createKeyEvent?: Maybe<Scalars['Void']>
  deleteBundle?: Maybe<Scalars['Void']>
  deleteDocument: Document
  deleteKeyEvent?: Maybe<Scalars['Void']>
  moveDocumentAfter?: Maybe<Scalars['Void']>
  moveKeyEventAfter?: Maybe<Scalars['Void']>
  resetDocument: Document
  updateDocument: Document
  updateDocumentFile: Document
  updateKeyEvent: KeyEvent
  updateUserOffice?: Maybe<User>
}

export type MutationAddLocalDocumentsToBundleArgs = {
  input: AddLocalDocumentsToBundleInput
}

export type MutationCompleteBundleArgs = {
  input: CompleteBundleInput
}

export type MutationCreateBundleArgs = {
  input: CreateBundleInput
}

export type MutationCreateDossiersArgs = {
  input: CreateDossiersInput
}

export type MutationCreateKeyEventArgs = {
  input: CreateKeyEventInput
}

export type MutationDeleteBundleArgs = {
  id: Scalars['UUID']
}

export type MutationDeleteDocumentArgs = {
  id: Scalars['UUID']
}

export type MutationDeleteKeyEventArgs = {
  id: Scalars['UUID']
}

export type MutationMoveDocumentAfterArgs = {
  input: MoveDocumentAfterInput
}

export type MutationMoveKeyEventAfterArgs = {
  input: MoveKeyEventAfterInput
}

export type MutationResetDocumentArgs = {
  id: Scalars['UUID']
}

export type MutationUpdateDocumentArgs = {
  input: UpdateDocumentInput
}

export type MutationUpdateDocumentFileArgs = {
  input: UpdateDocumentFileInput
}

export type MutationUpdateKeyEventArgs = {
  input: UpdateKeyEventInput
}

export type MutationUpdateUserOfficeArgs = {
  id: Scalars['UUID']
}

export type Office = {
  __typename?: 'Office'
  geographicRegion: GeographicRegion
  id: Scalars['UUID']
  name: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  appealType: AppealType
  appealTypesByBusinessArea: Array<AppealType>
  bundle: Bundle
  bundles?: Maybe<Array<Maybe<Bundle>>>
  businessArea: BusinessArea
  businessAreas: Array<BusinessArea>
  decisionType: DecisionType
  decisionTypesByDocument: Array<DecisionType>
  document: Document
  documentType: DocumentType
  dossier: Dossier
  getCurrentUser: User
  keyEventsByDocument: Array<KeyEvent>
  offices: Array<Office>
  validateDrsSearch: DrsSearchResponse
}

export type QueryAppealTypeArgs = {
  id: Scalars['UUID']
}

export type QueryAppealTypesByBusinessAreaArgs = {
  businessAreaId: Scalars['UUID']
}

export type QueryBundleArgs = {
  id: Scalars['UUID']
}

export type QueryBundlesArgs = {
  status: BundleStatus
}

export type QueryBusinessAreaArgs = {
  id: Scalars['UUID']
}

export type QueryDecisionTypeArgs = {
  id: Scalars['UUID']
}

export type QueryDecisionTypesByDocumentArgs = {
  documentId: Scalars['UUID']
}

export type QueryDocumentArgs = {
  id: Scalars['UUID']
}

export type QueryDocumentTypeArgs = {
  id: Scalars['UUID']
}

export type QueryDossierArgs = {
  id: Scalars['UUID']
}

export type QueryKeyEventsByDocumentArgs = {
  documentId: Scalars['UUID']
}

export type QueryValidateDrsSearchArgs = {
  input: DrsSearchInput
}

export type UpdateDocumentFileInput = {
  documentId: Scalars['UUID']
  fileS3ObjectKey: Scalars['String']
}

export type UpdateDocumentInput = {
  date?: InputMaybe<Scalars['Date']>
  dateType?: InputMaybe<DocumentDateType>
  decisionTypeId?: InputMaybe<Scalars['UUID']>
  id: Scalars['UUID']
  name?: InputMaybe<Scalars['String']>
  status?: InputMaybe<DocumentStatus>
}

export type UpdateKeyEventInput = {
  body?: InputMaybe<Scalars['String']>
  dateType?: InputMaybe<KeyEventDateType>
  id: Scalars['UUID']
  occuredAt?: InputMaybe<Scalars['Date']>
  pageFrom?: InputMaybe<Scalars['Int']>
  pageTo?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<KeyEventStatus>
}

export type UploadDossiersInput = {
  bundleId: Scalars['UUID']
}

export type User = {
  __typename?: 'User'
  createdAt: Scalars['DateTime']
  firstName?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  lastName?: Maybe<Scalars['String']>
  office?: Maybe<Office>
  updatedAt: Scalars['DateTime']
}

export type AddLocalDocumentsToBundleMutationVariables = Exact<{
  input: AddLocalDocumentsToBundleInput
}>

export type AddLocalDocumentsToBundleMutation = {
  __typename?: 'Mutation'
  addLocalDocumentsToBundle: {
    __typename?: 'Bundle'
    id: string
    documents: Array<{
      __typename?: 'Document'
      id: string
      name?: string | null
      date?: string | null
      status: DocumentStatus
      dateType?: DocumentDateType | null
    }>
  }
}

export type CompleteBundleMutationVariables = Exact<{
  input: CompleteBundleInput
}>

export type CompleteBundleMutation = {
  __typename?: 'Mutation'
  completeBundle: { __typename?: 'Bundle'; id: string; status: BundleStatus }
}

export type CreateBundleMutationVariables = Exact<{
  input: CreateBundleInput
}>

export type CreateBundleMutation = {
  __typename?: 'Mutation'
  createBundle: {
    __typename?: 'Bundle'
    id: string
    customerName: string
    createdAt: string
    businessArea: { __typename?: 'BusinessArea'; name: string }
    appealType?: { __typename?: 'AppealType'; name: string } | null
  }
}

export type CreateDossiersMutationVariables = Exact<{
  input: CreateDossiersInput
}>

export type CreateDossiersMutation = {
  __typename?: 'Mutation'
  createDossiers: Array<{ __typename?: 'Dossier'; id: string }>
}

export type CreateKeyEventMutationVariables = Exact<{
  input: CreateKeyEventInput
}>

export type CreateKeyEventMutation = {
  __typename?: 'Mutation'
  createKeyEvent?: any | null
}

export type DeleteBundleMutationVariables = Exact<{
  deleteBundleId: Scalars['UUID']
}>

export type DeleteBundleMutation = {
  __typename?: 'Mutation'
  deleteBundle?: any | null
}

export type DeleteDocumentMutationVariables = Exact<{
  deleteDocumentId: Scalars['UUID']
}>

export type DeleteDocumentMutation = {
  __typename?: 'Mutation'
  deleteDocument: { __typename?: 'Document'; id: string }
}

export type DeleteKeyEventMutationVariables = Exact<{
  deleteKeyEventId: Scalars['UUID']
}>

export type DeleteKeyEventMutation = {
  __typename?: 'Mutation'
  deleteKeyEvent?: any | null
}

export type MoveDocumentAfterMutationVariables = Exact<{
  input: MoveDocumentAfterInput
}>

export type MoveDocumentAfterMutation = {
  __typename?: 'Mutation'
  moveDocumentAfter?: any | null
}

export type MoveKeyEventAfterMutationVariables = Exact<{
  input: MoveKeyEventAfterInput
}>

export type MoveKeyEventAfterMutation = {
  __typename?: 'Mutation'
  moveKeyEventAfter?: any | null
}

export type ResetDocumentMutationVariables = Exact<{
  resetDocumentId: Scalars['UUID']
}>

export type ResetDocumentMutation = {
  __typename?: 'Mutation'
  resetDocument: { __typename?: 'Document'; id: string }
}

export type UpdateDocumentMutationVariables = Exact<{
  input: UpdateDocumentInput
}>

export type UpdateDocumentMutation = {
  __typename?: 'Mutation'
  updateDocument: {
    __typename?: 'Document'
    id: string
    name?: string | null
    dateType?: DocumentDateType | null
    date?: string | null
    status: DocumentStatus
    decisionType?: {
      __typename?: 'DecisionType'
      id: string
      name: string
    } | null
  }
}

export type UpdateKeyEventMutationVariables = Exact<{
  input: UpdateKeyEventInput
}>

export type UpdateKeyEventMutation = {
  __typename?: 'Mutation'
  updateKeyEvent: {
    __typename?: 'KeyEvent'
    id: string
    documentId: string
    source: KeyEventSource
    occuredAt?: string | null
    body: string
    pageFrom?: number | null
    pageTo?: number | null
    status: KeyEventStatus
    sort: number
    createdAt: string
    updatedAt: string
    dateType?: KeyEventDateType | null
  }
}

export type UpdateUserOfficeMutationVariables = Exact<{
  id: Scalars['UUID']
}>

export type UpdateUserOfficeMutation = {
  __typename?: 'Mutation'
  updateUserOffice?: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    office?: {
      __typename?: 'Office'
      id: string
      name: string
      geographicRegion: {
        __typename?: 'GeographicRegion'
        id: string
        name: string
      }
    } | null
  } | null
}

export type AppealTypesByBusinessAreaQueryVariables = Exact<{
  businessAreaId: Scalars['UUID']
}>

export type AppealTypesByBusinessAreaQuery = {
  __typename?: 'Query'
  appealTypesByBusinessArea: Array<{
    __typename?: 'AppealType'
    name: string
    id: string
  }>
}

export type GetBundleDocumentsByBundleIdQueryVariables = Exact<{
  bundleId: Scalars['UUID']
}>

export type GetBundleDocumentsByBundleIdQuery = {
  __typename?: 'Query'
  bundle: {
    __typename?: 'Bundle'
    id: string
    documents: Array<{
      __typename?: 'Document'
      id: string
      name?: string | null
      date?: string | null
      status: DocumentStatus
      dateType?: DocumentDateType | null
      optimisedFileSizeInBytes?: number | null
      initialPageCount?: number | null
      keyEvents?: Array<{
        __typename?: 'KeyEvent'
        id: string
        status: KeyEventStatus
      } | null> | null
    }>
  }
}

export type GetBundleDocumentsStatusByBundleIdQueryVariables = Exact<{
  bundleId: Scalars['UUID']
}>

export type GetBundleDocumentsStatusByBundleIdQuery = {
  __typename?: 'Query'
  bundle: {
    __typename?: 'Bundle'
    id: string
    status: BundleStatus
    documents: Array<{
      __typename?: 'Document'
      id: string
      status: DocumentStatus
    }>
  }
}

export type GetBundleInformationQueryVariables = Exact<{
  bundleId: Scalars['UUID']
}>

export type GetBundleInformationQuery = {
  __typename?: 'Query'
  bundle: {
    __typename?: 'Bundle'
    id: string
    customerName: string
    createdAt: string
    status: BundleStatus
    keyEventsPdfFileS3ObjectKey?: string | null
    keyEventsCsvFileS3ObjectKey?: string | null
    keyEventStatus?: KeyEventGenerationStatus | null
    drsDocumentIdentifierType?: DrsDocumentIdentifierType | null
    drsDocumentIdentifier?: string | null
    businessArea: { __typename?: 'BusinessArea'; name: string }
    appealType?: { __typename?: 'AppealType'; name: string } | null
  }
}

export type GetBusinessAreasQueryVariables = Exact<{ [key: string]: never }>

export type GetBusinessAreasQuery = {
  __typename?: 'Query'
  businessAreas: Array<{
    __typename?: 'BusinessArea'
    id: string
    name: string
    isDrsEnabled: boolean
  }>
}

export type GetCompletedBundlesQueryVariables = Exact<{
  bundlesStatus: BundleStatus
}>

export type GetCompletedBundlesQuery = {
  __typename?: 'Query'
  bundles?: Array<{
    __typename?: 'Bundle'
    id: string
    customerName: string
    keyEventsPdfFileS3ObjectKey?: string | null
    keyEventsCsvFileS3ObjectKey?: string | null
    keyEventStatus?: KeyEventGenerationStatus | null
    businessArea: { __typename?: 'BusinessArea'; name: string }
    appealType?: { __typename?: 'AppealType'; name: string } | null
    dossiers: Array<{
      __typename?: 'Dossier'
      id: string
      type: DossierType
      fileS3ObjectKey?: string | null
      status: DossierStatus
    }>
  } | null> | null
}

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentUserQuery = {
  __typename?: 'Query'
  getCurrentUser: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    office?: {
      __typename?: 'Office'
      id: string
      name: string
      geographicRegion: {
        __typename?: 'GeographicRegion'
        id: string
        name: string
      }
    } | null
  }
}

export type GetDocumentQueryVariables = Exact<{
  documentId: Scalars['UUID']
}>

export type GetDocumentQuery = {
  __typename?: 'Query'
  document: {
    __typename?: 'Document'
    id: string
    fileS3ObjectKey?: string | null
    name?: string | null
    dateType?: DocumentDateType | null
    date?: string | null
    status: DocumentStatus
    initialPageCount?: number | null
    optimisedFileSizeInBytes?: number | null
    documentAnalysisStatus: DocumentAnalysisStatus
    documentType?: {
      __typename?: 'DocumentType'
      id: string
      relatedDocumentNames?: Array<string> | null
      decisionTypes?: Array<{
        __typename?: 'DecisionType'
        id: string
        name: string
      }> | null
    } | null
    decisionType?: {
      __typename?: 'DecisionType'
      id: string
      name: string
    } | null
  }
}

export type GetDossiersByBundleIdQueryVariables = Exact<{
  bundleId: Scalars['UUID']
}>

export type GetDossiersByBundleIdQuery = {
  __typename?: 'Query'
  bundle: {
    __typename?: 'Bundle'
    id: string
    createdAt: string
    customerName: string
    dossiers: Array<{
      __typename?: 'Dossier'
      id: string
      type: DossierType
      fileS3ObjectKey?: string | null
      status: DossierStatus
    }>
  }
}

export type GetDossiersDrsUploadStatusQueryVariables = Exact<{
  bundleId: Scalars['UUID']
}>

export type GetDossiersDrsUploadStatusQuery = {
  __typename?: 'Query'
  bundle: {
    __typename?: 'Bundle'
    id: string
    dossiers: Array<{
      __typename?: 'Dossier'
      id: string
      type: DossierType
      isUploaded: boolean
      hasUploadingFailed: boolean
    }>
  }
}

export type GetDossiersWithDrsDocumentIdentifierQueryVariables = Exact<{
  bundleId: Scalars['UUID']
}>

export type GetDossiersWithDrsDocumentIdentifierQuery = {
  __typename?: 'Query'
  bundle: {
    __typename?: 'Bundle'
    id: string
    drsDocumentIdentifierType?: DrsDocumentIdentifierType | null
    customerName: string
    status: BundleStatus
    isUploadBundleToDrs: boolean
    dossiers: Array<{
      __typename?: 'Dossier'
      id: string
      type: DossierType
      fileS3ObjectKey?: string | null
      status: DossierStatus
      fileSize?: number | null
    }>
  }
}

export type GetKeyEventsByDocumentQueryVariables = Exact<{
  documentId: Scalars['UUID']
}>

export type GetKeyEventsByDocumentQuery = {
  __typename?: 'Query'
  keyEventsByDocument: Array<{
    __typename?: 'KeyEvent'
    id: string
    documentId: string
    source: KeyEventSource
    occuredAt?: string | null
    body: string
    pageFrom?: number | null
    pageTo?: number | null
    status: KeyEventStatus
    sort: number
    createdAt: string
    updatedAt: string
    dateType?: KeyEventDateType | null
  }>
}

export type GetOfficesQueryVariables = Exact<{ [key: string]: never }>

export type GetOfficesQuery = {
  __typename?: 'Query'
  offices: Array<{ __typename?: 'Office'; id: string; name: string }>
}

export type GetSavedBundlesQueryVariables = Exact<{
  bundlesStatus: BundleStatus
}>

export type GetSavedBundlesQuery = {
  __typename?: 'Query'
  bundles?: Array<{
    __typename?: 'Bundle'
    id: string
    customerName: string
    createdAt: string
    businessArea: { __typename?: 'BusinessArea'; name: string }
    appealType?: { __typename?: 'AppealType'; name: string } | null
  } | null> | null
}

export type ValidateDrsSearchQueryVariables = Exact<{
  validateDrsSearchInput: DrsSearchInput
}>

export type ValidateDrsSearchQuery = {
  __typename?: 'Query'
  validateDrsSearch: { __typename?: 'DrsSearchResponse'; documentCount: number }
}

export const AddLocalDocumentsToBundleDocument = gql`
  mutation AddLocalDocumentsToBundle($input: AddLocalDocumentsToBundleInput!) {
    addLocalDocumentsToBundle(input: $input) {
      id
      documents {
        id
        name
        date
        status
        dateType
      }
    }
  }
`
export type AddLocalDocumentsToBundleMutationFn = Apollo.MutationFunction<
  AddLocalDocumentsToBundleMutation,
  AddLocalDocumentsToBundleMutationVariables
>

/**
 * __useAddLocalDocumentsToBundleMutation__
 *
 * To run a mutation, you first call `useAddLocalDocumentsToBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLocalDocumentsToBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLocalDocumentsToBundleMutation, { data, loading, error }] = useAddLocalDocumentsToBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLocalDocumentsToBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLocalDocumentsToBundleMutation,
    AddLocalDocumentsToBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddLocalDocumentsToBundleMutation,
    AddLocalDocumentsToBundleMutationVariables
  >(AddLocalDocumentsToBundleDocument, options)
}
export type AddLocalDocumentsToBundleMutationHookResult = ReturnType<
  typeof useAddLocalDocumentsToBundleMutation
>
export type AddLocalDocumentsToBundleMutationResult =
  Apollo.MutationResult<AddLocalDocumentsToBundleMutation>
export type AddLocalDocumentsToBundleMutationOptions =
  Apollo.BaseMutationOptions<
    AddLocalDocumentsToBundleMutation,
    AddLocalDocumentsToBundleMutationVariables
  >
export const CompleteBundleDocument = gql`
  mutation CompleteBundle($input: CompleteBundleInput!) {
    completeBundle(input: $input) {
      id
      status
    }
  }
`
export type CompleteBundleMutationFn = Apollo.MutationFunction<
  CompleteBundleMutation,
  CompleteBundleMutationVariables
>

/**
 * __useCompleteBundleMutation__
 *
 * To run a mutation, you first call `useCompleteBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeBundleMutation, { data, loading, error }] = useCompleteBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteBundleMutation,
    CompleteBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompleteBundleMutation,
    CompleteBundleMutationVariables
  >(CompleteBundleDocument, options)
}
export type CompleteBundleMutationHookResult = ReturnType<
  typeof useCompleteBundleMutation
>
export type CompleteBundleMutationResult =
  Apollo.MutationResult<CompleteBundleMutation>
export type CompleteBundleMutationOptions = Apollo.BaseMutationOptions<
  CompleteBundleMutation,
  CompleteBundleMutationVariables
>
export const CreateBundleDocument = gql`
  mutation CreateBundle($input: CreateBundleInput!) {
    createBundle(input: $input) {
      id
      businessArea {
        name
      }
      appealType {
        name
      }
      customerName
      createdAt
    }
  }
`
export type CreateBundleMutationFn = Apollo.MutationFunction<
  CreateBundleMutation,
  CreateBundleMutationVariables
>

/**
 * __useCreateBundleMutation__
 *
 * To run a mutation, you first call `useCreateBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBundleMutation, { data, loading, error }] = useCreateBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBundleMutation,
    CreateBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateBundleMutation,
    CreateBundleMutationVariables
  >(CreateBundleDocument, options)
}
export type CreateBundleMutationHookResult = ReturnType<
  typeof useCreateBundleMutation
>
export type CreateBundleMutationResult =
  Apollo.MutationResult<CreateBundleMutation>
export type CreateBundleMutationOptions = Apollo.BaseMutationOptions<
  CreateBundleMutation,
  CreateBundleMutationVariables
>
export const CreateDossiersDocument = gql`
  mutation CreateDossiers($input: CreateDossiersInput!) {
    createDossiers(input: $input) {
      id
    }
  }
`
export type CreateDossiersMutationFn = Apollo.MutationFunction<
  CreateDossiersMutation,
  CreateDossiersMutationVariables
>

/**
 * __useCreateDossiersMutation__
 *
 * To run a mutation, you first call `useCreateDossiersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDossiersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDossiersMutation, { data, loading, error }] = useCreateDossiersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDossiersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDossiersMutation,
    CreateDossiersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDossiersMutation,
    CreateDossiersMutationVariables
  >(CreateDossiersDocument, options)
}
export type CreateDossiersMutationHookResult = ReturnType<
  typeof useCreateDossiersMutation
>
export type CreateDossiersMutationResult =
  Apollo.MutationResult<CreateDossiersMutation>
export type CreateDossiersMutationOptions = Apollo.BaseMutationOptions<
  CreateDossiersMutation,
  CreateDossiersMutationVariables
>
export const CreateKeyEventDocument = gql`
  mutation CreateKeyEvent($input: CreateKeyEventInput!) {
    createKeyEvent(input: $input)
  }
`
export type CreateKeyEventMutationFn = Apollo.MutationFunction<
  CreateKeyEventMutation,
  CreateKeyEventMutationVariables
>

/**
 * __useCreateKeyEventMutation__
 *
 * To run a mutation, you first call `useCreateKeyEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeyEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKeyEventMutation, { data, loading, error }] = useCreateKeyEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKeyEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKeyEventMutation,
    CreateKeyEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateKeyEventMutation,
    CreateKeyEventMutationVariables
  >(CreateKeyEventDocument, options)
}
export type CreateKeyEventMutationHookResult = ReturnType<
  typeof useCreateKeyEventMutation
>
export type CreateKeyEventMutationResult =
  Apollo.MutationResult<CreateKeyEventMutation>
export type CreateKeyEventMutationOptions = Apollo.BaseMutationOptions<
  CreateKeyEventMutation,
  CreateKeyEventMutationVariables
>
export const DeleteBundleDocument = gql`
  mutation DeleteBundle($deleteBundleId: UUID!) {
    deleteBundle(id: $deleteBundleId)
  }
`
export type DeleteBundleMutationFn = Apollo.MutationFunction<
  DeleteBundleMutation,
  DeleteBundleMutationVariables
>

/**
 * __useDeleteBundleMutation__
 *
 * To run a mutation, you first call `useDeleteBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBundleMutation, { data, loading, error }] = useDeleteBundleMutation({
 *   variables: {
 *      deleteBundleId: // value for 'deleteBundleId'
 *   },
 * });
 */
export function useDeleteBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBundleMutation,
    DeleteBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteBundleMutation,
    DeleteBundleMutationVariables
  >(DeleteBundleDocument, options)
}
export type DeleteBundleMutationHookResult = ReturnType<
  typeof useDeleteBundleMutation
>
export type DeleteBundleMutationResult =
  Apollo.MutationResult<DeleteBundleMutation>
export type DeleteBundleMutationOptions = Apollo.BaseMutationOptions<
  DeleteBundleMutation,
  DeleteBundleMutationVariables
>
export const DeleteDocumentDocument = gql`
  mutation DeleteDocument($deleteDocumentId: UUID!) {
    deleteDocument(id: $deleteDocumentId) {
      id
    }
  }
`
export type DeleteDocumentMutationFn = Apollo.MutationFunction<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      deleteDocumentId: // value for 'deleteDocumentId'
 *   },
 * });
 */
export function useDeleteDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >(DeleteDocumentDocument, options)
}
export type DeleteDocumentMutationHookResult = ReturnType<
  typeof useDeleteDocumentMutation
>
export type DeleteDocumentMutationResult =
  Apollo.MutationResult<DeleteDocumentMutation>
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>
export const DeleteKeyEventDocument = gql`
  mutation DeleteKeyEvent($deleteKeyEventId: UUID!) {
    deleteKeyEvent(id: $deleteKeyEventId)
  }
`
export type DeleteKeyEventMutationFn = Apollo.MutationFunction<
  DeleteKeyEventMutation,
  DeleteKeyEventMutationVariables
>

/**
 * __useDeleteKeyEventMutation__
 *
 * To run a mutation, you first call `useDeleteKeyEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKeyEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKeyEventMutation, { data, loading, error }] = useDeleteKeyEventMutation({
 *   variables: {
 *      deleteKeyEventId: // value for 'deleteKeyEventId'
 *   },
 * });
 */
export function useDeleteKeyEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteKeyEventMutation,
    DeleteKeyEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteKeyEventMutation,
    DeleteKeyEventMutationVariables
  >(DeleteKeyEventDocument, options)
}
export type DeleteKeyEventMutationHookResult = ReturnType<
  typeof useDeleteKeyEventMutation
>
export type DeleteKeyEventMutationResult =
  Apollo.MutationResult<DeleteKeyEventMutation>
export type DeleteKeyEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteKeyEventMutation,
  DeleteKeyEventMutationVariables
>
export const MoveDocumentAfterDocument = gql`
  mutation MoveDocumentAfter($input: MoveDocumentAfterInput!) {
    moveDocumentAfter(input: $input)
  }
`
export type MoveDocumentAfterMutationFn = Apollo.MutationFunction<
  MoveDocumentAfterMutation,
  MoveDocumentAfterMutationVariables
>

/**
 * __useMoveDocumentAfterMutation__
 *
 * To run a mutation, you first call `useMoveDocumentAfterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveDocumentAfterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveDocumentAfterMutation, { data, loading, error }] = useMoveDocumentAfterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveDocumentAfterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveDocumentAfterMutation,
    MoveDocumentAfterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MoveDocumentAfterMutation,
    MoveDocumentAfterMutationVariables
  >(MoveDocumentAfterDocument, options)
}
export type MoveDocumentAfterMutationHookResult = ReturnType<
  typeof useMoveDocumentAfterMutation
>
export type MoveDocumentAfterMutationResult =
  Apollo.MutationResult<MoveDocumentAfterMutation>
export type MoveDocumentAfterMutationOptions = Apollo.BaseMutationOptions<
  MoveDocumentAfterMutation,
  MoveDocumentAfterMutationVariables
>
export const MoveKeyEventAfterDocument = gql`
  mutation MoveKeyEventAfter($input: MoveKeyEventAfterInput!) {
    moveKeyEventAfter(input: $input)
  }
`
export type MoveKeyEventAfterMutationFn = Apollo.MutationFunction<
  MoveKeyEventAfterMutation,
  MoveKeyEventAfterMutationVariables
>

/**
 * __useMoveKeyEventAfterMutation__
 *
 * To run a mutation, you first call `useMoveKeyEventAfterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveKeyEventAfterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveKeyEventAfterMutation, { data, loading, error }] = useMoveKeyEventAfterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveKeyEventAfterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveKeyEventAfterMutation,
    MoveKeyEventAfterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MoveKeyEventAfterMutation,
    MoveKeyEventAfterMutationVariables
  >(MoveKeyEventAfterDocument, options)
}
export type MoveKeyEventAfterMutationHookResult = ReturnType<
  typeof useMoveKeyEventAfterMutation
>
export type MoveKeyEventAfterMutationResult =
  Apollo.MutationResult<MoveKeyEventAfterMutation>
export type MoveKeyEventAfterMutationOptions = Apollo.BaseMutationOptions<
  MoveKeyEventAfterMutation,
  MoveKeyEventAfterMutationVariables
>
export const ResetDocumentDocument = gql`
  mutation ResetDocument($resetDocumentId: UUID!) {
    resetDocument(id: $resetDocumentId) {
      id
    }
  }
`
export type ResetDocumentMutationFn = Apollo.MutationFunction<
  ResetDocumentMutation,
  ResetDocumentMutationVariables
>

/**
 * __useResetDocumentMutation__
 *
 * To run a mutation, you first call `useResetDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetDocumentMutation, { data, loading, error }] = useResetDocumentMutation({
 *   variables: {
 *      resetDocumentId: // value for 'resetDocumentId'
 *   },
 * });
 */
export function useResetDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetDocumentMutation,
    ResetDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetDocumentMutation,
    ResetDocumentMutationVariables
  >(ResetDocumentDocument, options)
}
export type ResetDocumentMutationHookResult = ReturnType<
  typeof useResetDocumentMutation
>
export type ResetDocumentMutationResult =
  Apollo.MutationResult<ResetDocumentMutation>
export type ResetDocumentMutationOptions = Apollo.BaseMutationOptions<
  ResetDocumentMutation,
  ResetDocumentMutationVariables
>
export const UpdateDocumentDocument = gql`
  mutation UpdateDocument($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      id
      name
      dateType
      date
      decisionType {
        id
        name
      }
      status
    }
  }
`
export type UpdateDocumentMutationFn = Apollo.MutationFunction<
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables
>

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDocumentMutation,
    UpdateDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDocumentMutation,
    UpdateDocumentMutationVariables
  >(UpdateDocumentDocument, options)
}
export type UpdateDocumentMutationHookResult = ReturnType<
  typeof useUpdateDocumentMutation
>
export type UpdateDocumentMutationResult =
  Apollo.MutationResult<UpdateDocumentMutation>
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables
>
export const UpdateKeyEventDocument = gql`
  mutation UpdateKeyEvent($input: UpdateKeyEventInput!) {
    updateKeyEvent(input: $input) {
      id
      documentId
      source
      occuredAt
      body
      pageFrom
      pageTo
      status
      sort
      createdAt
      updatedAt
      dateType
    }
  }
`
export type UpdateKeyEventMutationFn = Apollo.MutationFunction<
  UpdateKeyEventMutation,
  UpdateKeyEventMutationVariables
>

/**
 * __useUpdateKeyEventMutation__
 *
 * To run a mutation, you first call `useUpdateKeyEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKeyEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKeyEventMutation, { data, loading, error }] = useUpdateKeyEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKeyEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateKeyEventMutation,
    UpdateKeyEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateKeyEventMutation,
    UpdateKeyEventMutationVariables
  >(UpdateKeyEventDocument, options)
}
export type UpdateKeyEventMutationHookResult = ReturnType<
  typeof useUpdateKeyEventMutation
>
export type UpdateKeyEventMutationResult =
  Apollo.MutationResult<UpdateKeyEventMutation>
export type UpdateKeyEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateKeyEventMutation,
  UpdateKeyEventMutationVariables
>
export const UpdateUserOfficeDocument = gql`
  mutation UpdateUserOffice($id: UUID!) {
    updateUserOffice(id: $id) {
      id
      firstName
      lastName
      office {
        id
        name
        geographicRegion {
          id
          name
        }
      }
    }
  }
`
export type UpdateUserOfficeMutationFn = Apollo.MutationFunction<
  UpdateUserOfficeMutation,
  UpdateUserOfficeMutationVariables
>

/**
 * __useUpdateUserOfficeMutation__
 *
 * To run a mutation, you first call `useUpdateUserOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOfficeMutation, { data, loading, error }] = useUpdateUserOfficeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserOfficeMutation,
    UpdateUserOfficeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserOfficeMutation,
    UpdateUserOfficeMutationVariables
  >(UpdateUserOfficeDocument, options)
}
export type UpdateUserOfficeMutationHookResult = ReturnType<
  typeof useUpdateUserOfficeMutation
>
export type UpdateUserOfficeMutationResult =
  Apollo.MutationResult<UpdateUserOfficeMutation>
export type UpdateUserOfficeMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserOfficeMutation,
  UpdateUserOfficeMutationVariables
>
export const AppealTypesByBusinessAreaDocument = gql`
  query AppealTypesByBusinessArea($businessAreaId: UUID!) {
    appealTypesByBusinessArea(businessAreaId: $businessAreaId) {
      name
      id
    }
  }
`

/**
 * __useAppealTypesByBusinessAreaQuery__
 *
 * To run a query within a React component, call `useAppealTypesByBusinessAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppealTypesByBusinessAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppealTypesByBusinessAreaQuery({
 *   variables: {
 *      businessAreaId: // value for 'businessAreaId'
 *   },
 * });
 */
export function useAppealTypesByBusinessAreaQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppealTypesByBusinessAreaQuery,
    AppealTypesByBusinessAreaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AppealTypesByBusinessAreaQuery,
    AppealTypesByBusinessAreaQueryVariables
  >(AppealTypesByBusinessAreaDocument, options)
}
export function useAppealTypesByBusinessAreaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppealTypesByBusinessAreaQuery,
    AppealTypesByBusinessAreaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AppealTypesByBusinessAreaQuery,
    AppealTypesByBusinessAreaQueryVariables
  >(AppealTypesByBusinessAreaDocument, options)
}
export type AppealTypesByBusinessAreaQueryHookResult = ReturnType<
  typeof useAppealTypesByBusinessAreaQuery
>
export type AppealTypesByBusinessAreaLazyQueryHookResult = ReturnType<
  typeof useAppealTypesByBusinessAreaLazyQuery
>
export type AppealTypesByBusinessAreaQueryResult = Apollo.QueryResult<
  AppealTypesByBusinessAreaQuery,
  AppealTypesByBusinessAreaQueryVariables
>
export const GetBundleDocumentsByBundleIdDocument = gql`
  query GetBundleDocumentsByBundleId($bundleId: UUID!) {
    bundle(id: $bundleId) {
      id
      documents {
        id
        name
        date
        status
        dateType
        optimisedFileSizeInBytes
        initialPageCount
        keyEvents {
          id
          status
        }
      }
    }
  }
`

/**
 * __useGetBundleDocumentsByBundleIdQuery__
 *
 * To run a query within a React component, call `useGetBundleDocumentsByBundleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBundleDocumentsByBundleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBundleDocumentsByBundleIdQuery({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *   },
 * });
 */
export function useGetBundleDocumentsByBundleIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBundleDocumentsByBundleIdQuery,
    GetBundleDocumentsByBundleIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetBundleDocumentsByBundleIdQuery,
    GetBundleDocumentsByBundleIdQueryVariables
  >(GetBundleDocumentsByBundleIdDocument, options)
}
export function useGetBundleDocumentsByBundleIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBundleDocumentsByBundleIdQuery,
    GetBundleDocumentsByBundleIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBundleDocumentsByBundleIdQuery,
    GetBundleDocumentsByBundleIdQueryVariables
  >(GetBundleDocumentsByBundleIdDocument, options)
}
export type GetBundleDocumentsByBundleIdQueryHookResult = ReturnType<
  typeof useGetBundleDocumentsByBundleIdQuery
>
export type GetBundleDocumentsByBundleIdLazyQueryHookResult = ReturnType<
  typeof useGetBundleDocumentsByBundleIdLazyQuery
>
export type GetBundleDocumentsByBundleIdQueryResult = Apollo.QueryResult<
  GetBundleDocumentsByBundleIdQuery,
  GetBundleDocumentsByBundleIdQueryVariables
>
export const GetBundleDocumentsStatusByBundleIdDocument = gql`
  query GetBundleDocumentsStatusByBundleId($bundleId: UUID!) {
    bundle(id: $bundleId) {
      id
      status
      documents {
        id
        status
      }
    }
  }
`

/**
 * __useGetBundleDocumentsStatusByBundleIdQuery__
 *
 * To run a query within a React component, call `useGetBundleDocumentsStatusByBundleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBundleDocumentsStatusByBundleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBundleDocumentsStatusByBundleIdQuery({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *   },
 * });
 */
export function useGetBundleDocumentsStatusByBundleIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBundleDocumentsStatusByBundleIdQuery,
    GetBundleDocumentsStatusByBundleIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetBundleDocumentsStatusByBundleIdQuery,
    GetBundleDocumentsStatusByBundleIdQueryVariables
  >(GetBundleDocumentsStatusByBundleIdDocument, options)
}
export function useGetBundleDocumentsStatusByBundleIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBundleDocumentsStatusByBundleIdQuery,
    GetBundleDocumentsStatusByBundleIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBundleDocumentsStatusByBundleIdQuery,
    GetBundleDocumentsStatusByBundleIdQueryVariables
  >(GetBundleDocumentsStatusByBundleIdDocument, options)
}
export type GetBundleDocumentsStatusByBundleIdQueryHookResult = ReturnType<
  typeof useGetBundleDocumentsStatusByBundleIdQuery
>
export type GetBundleDocumentsStatusByBundleIdLazyQueryHookResult = ReturnType<
  typeof useGetBundleDocumentsStatusByBundleIdLazyQuery
>
export type GetBundleDocumentsStatusByBundleIdQueryResult = Apollo.QueryResult<
  GetBundleDocumentsStatusByBundleIdQuery,
  GetBundleDocumentsStatusByBundleIdQueryVariables
>
export const GetBundleInformationDocument = gql`
  query GetBundleInformation($bundleId: UUID!) {
    bundle(id: $bundleId) {
      id
      businessArea {
        name
      }
      appealType {
        name
      }
      customerName
      createdAt
      status
      keyEventsPdfFileS3ObjectKey
      keyEventsCsvFileS3ObjectKey
      keyEventStatus
      drsDocumentIdentifierType
      drsDocumentIdentifier
    }
  }
`

/**
 * __useGetBundleInformationQuery__
 *
 * To run a query within a React component, call `useGetBundleInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBundleInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBundleInformationQuery({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *   },
 * });
 */
export function useGetBundleInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBundleInformationQuery,
    GetBundleInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetBundleInformationQuery,
    GetBundleInformationQueryVariables
  >(GetBundleInformationDocument, options)
}
export function useGetBundleInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBundleInformationQuery,
    GetBundleInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBundleInformationQuery,
    GetBundleInformationQueryVariables
  >(GetBundleInformationDocument, options)
}
export type GetBundleInformationQueryHookResult = ReturnType<
  typeof useGetBundleInformationQuery
>
export type GetBundleInformationLazyQueryHookResult = ReturnType<
  typeof useGetBundleInformationLazyQuery
>
export type GetBundleInformationQueryResult = Apollo.QueryResult<
  GetBundleInformationQuery,
  GetBundleInformationQueryVariables
>
export const GetBusinessAreasDocument = gql`
  query GetBusinessAreas {
    businessAreas {
      id
      name
      isDrsEnabled
    }
  }
`

/**
 * __useGetBusinessAreasQuery__
 *
 * To run a query within a React component, call `useGetBusinessAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBusinessAreasQuery,
    GetBusinessAreasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBusinessAreasQuery, GetBusinessAreasQueryVariables>(
    GetBusinessAreasDocument,
    options
  )
}
export function useGetBusinessAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBusinessAreasQuery,
    GetBusinessAreasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBusinessAreasQuery,
    GetBusinessAreasQueryVariables
  >(GetBusinessAreasDocument, options)
}
export type GetBusinessAreasQueryHookResult = ReturnType<
  typeof useGetBusinessAreasQuery
>
export type GetBusinessAreasLazyQueryHookResult = ReturnType<
  typeof useGetBusinessAreasLazyQuery
>
export type GetBusinessAreasQueryResult = Apollo.QueryResult<
  GetBusinessAreasQuery,
  GetBusinessAreasQueryVariables
>
export const GetCompletedBundlesDocument = gql`
  query GetCompletedBundles($bundlesStatus: BundleStatus!) {
    bundles(status: $bundlesStatus) {
      id
      customerName
      businessArea {
        name
      }
      appealType {
        name
      }
      dossiers {
        id
        type
        fileS3ObjectKey
        status
      }
      keyEventsPdfFileS3ObjectKey
      keyEventsCsvFileS3ObjectKey
      keyEventStatus
    }
  }
`

/**
 * __useGetCompletedBundlesQuery__
 *
 * To run a query within a React component, call `useGetCompletedBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedBundlesQuery({
 *   variables: {
 *      bundlesStatus: // value for 'bundlesStatus'
 *   },
 * });
 */
export function useGetCompletedBundlesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompletedBundlesQuery,
    GetCompletedBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCompletedBundlesQuery,
    GetCompletedBundlesQueryVariables
  >(GetCompletedBundlesDocument, options)
}
export function useGetCompletedBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompletedBundlesQuery,
    GetCompletedBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCompletedBundlesQuery,
    GetCompletedBundlesQueryVariables
  >(GetCompletedBundlesDocument, options)
}
export type GetCompletedBundlesQueryHookResult = ReturnType<
  typeof useGetCompletedBundlesQuery
>
export type GetCompletedBundlesLazyQueryHookResult = ReturnType<
  typeof useGetCompletedBundlesLazyQuery
>
export type GetCompletedBundlesQueryResult = Apollo.QueryResult<
  GetCompletedBundlesQuery,
  GetCompletedBundlesQueryVariables
>
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      firstName
      lastName
      office {
        id
        name
        geographicRegion {
          id
          name
        }
      }
    }
  }
`

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  )
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  )
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>
export const GetDocumentDocument = gql`
  query GetDocument($documentId: UUID!) {
    document(id: $documentId) {
      id
      documentType {
        id
        relatedDocumentNames
        decisionTypes {
          id
          name
        }
      }
      decisionType {
        id
        name
      }
      fileS3ObjectKey
      name
      dateType
      date
      status
      initialPageCount
      optimisedFileSizeInBytes
      documentAnalysisStatus
    }
  }
`

/**
 * __useGetDocumentQuery__
 *
 * To run a query within a React component, call `useGetDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDocumentQuery,
    GetDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDocumentQuery, GetDocumentQueryVariables>(
    GetDocumentDocument,
    options
  )
}
export function useGetDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocumentQuery,
    GetDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDocumentQuery, GetDocumentQueryVariables>(
    GetDocumentDocument,
    options
  )
}
export type GetDocumentQueryHookResult = ReturnType<typeof useGetDocumentQuery>
export type GetDocumentLazyQueryHookResult = ReturnType<
  typeof useGetDocumentLazyQuery
>
export type GetDocumentQueryResult = Apollo.QueryResult<
  GetDocumentQuery,
  GetDocumentQueryVariables
>
export const GetDossiersByBundleIdDocument = gql`
  query GetDossiersByBundleId($bundleId: UUID!) {
    bundle(id: $bundleId) {
      id
      createdAt
      customerName
      dossiers {
        id
        type
        fileS3ObjectKey
        status
      }
    }
  }
`

/**
 * __useGetDossiersByBundleIdQuery__
 *
 * To run a query within a React component, call `useGetDossiersByBundleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDossiersByBundleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDossiersByBundleIdQuery({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *   },
 * });
 */
export function useGetDossiersByBundleIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDossiersByBundleIdQuery,
    GetDossiersByBundleIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDossiersByBundleIdQuery,
    GetDossiersByBundleIdQueryVariables
  >(GetDossiersByBundleIdDocument, options)
}
export function useGetDossiersByBundleIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDossiersByBundleIdQuery,
    GetDossiersByBundleIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDossiersByBundleIdQuery,
    GetDossiersByBundleIdQueryVariables
  >(GetDossiersByBundleIdDocument, options)
}
export type GetDossiersByBundleIdQueryHookResult = ReturnType<
  typeof useGetDossiersByBundleIdQuery
>
export type GetDossiersByBundleIdLazyQueryHookResult = ReturnType<
  typeof useGetDossiersByBundleIdLazyQuery
>
export type GetDossiersByBundleIdQueryResult = Apollo.QueryResult<
  GetDossiersByBundleIdQuery,
  GetDossiersByBundleIdQueryVariables
>
export const GetDossiersDrsUploadStatusDocument = gql`
  query GetDossiersDrsUploadStatus($bundleId: UUID!) {
    bundle(id: $bundleId) {
      id
      dossiers {
        id
        type
        isUploaded
        hasUploadingFailed
      }
    }
  }
`

/**
 * __useGetDossiersDrsUploadStatusQuery__
 *
 * To run a query within a React component, call `useGetDossiersDrsUploadStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDossiersDrsUploadStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDossiersDrsUploadStatusQuery({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *   },
 * });
 */
export function useGetDossiersDrsUploadStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDossiersDrsUploadStatusQuery,
    GetDossiersDrsUploadStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDossiersDrsUploadStatusQuery,
    GetDossiersDrsUploadStatusQueryVariables
  >(GetDossiersDrsUploadStatusDocument, options)
}
export function useGetDossiersDrsUploadStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDossiersDrsUploadStatusQuery,
    GetDossiersDrsUploadStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDossiersDrsUploadStatusQuery,
    GetDossiersDrsUploadStatusQueryVariables
  >(GetDossiersDrsUploadStatusDocument, options)
}
export type GetDossiersDrsUploadStatusQueryHookResult = ReturnType<
  typeof useGetDossiersDrsUploadStatusQuery
>
export type GetDossiersDrsUploadStatusLazyQueryHookResult = ReturnType<
  typeof useGetDossiersDrsUploadStatusLazyQuery
>
export type GetDossiersDrsUploadStatusQueryResult = Apollo.QueryResult<
  GetDossiersDrsUploadStatusQuery,
  GetDossiersDrsUploadStatusQueryVariables
>
export const GetDossiersWithDrsDocumentIdentifierDocument = gql`
  query GetDossiersWithDrsDocumentIdentifier($bundleId: UUID!) {
    bundle(id: $bundleId) {
      id
      drsDocumentIdentifierType
      customerName
      status
      isUploadBundleToDrs
      dossiers {
        id
        type
        fileS3ObjectKey
        status
        fileSize
      }
    }
  }
`

/**
 * __useGetDossiersWithDrsDocumentIdentifierQuery__
 *
 * To run a query within a React component, call `useGetDossiersWithDrsDocumentIdentifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDossiersWithDrsDocumentIdentifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDossiersWithDrsDocumentIdentifierQuery({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *   },
 * });
 */
export function useGetDossiersWithDrsDocumentIdentifierQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDossiersWithDrsDocumentIdentifierQuery,
    GetDossiersWithDrsDocumentIdentifierQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDossiersWithDrsDocumentIdentifierQuery,
    GetDossiersWithDrsDocumentIdentifierQueryVariables
  >(GetDossiersWithDrsDocumentIdentifierDocument, options)
}
export function useGetDossiersWithDrsDocumentIdentifierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDossiersWithDrsDocumentIdentifierQuery,
    GetDossiersWithDrsDocumentIdentifierQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDossiersWithDrsDocumentIdentifierQuery,
    GetDossiersWithDrsDocumentIdentifierQueryVariables
  >(GetDossiersWithDrsDocumentIdentifierDocument, options)
}
export type GetDossiersWithDrsDocumentIdentifierQueryHookResult = ReturnType<
  typeof useGetDossiersWithDrsDocumentIdentifierQuery
>
export type GetDossiersWithDrsDocumentIdentifierLazyQueryHookResult =
  ReturnType<typeof useGetDossiersWithDrsDocumentIdentifierLazyQuery>
export type GetDossiersWithDrsDocumentIdentifierQueryResult =
  Apollo.QueryResult<
    GetDossiersWithDrsDocumentIdentifierQuery,
    GetDossiersWithDrsDocumentIdentifierQueryVariables
  >
export const GetKeyEventsByDocumentDocument = gql`
  query GetKeyEventsByDocument($documentId: UUID!) {
    keyEventsByDocument(documentId: $documentId) {
      id
      documentId
      source
      occuredAt
      body
      pageFrom
      pageTo
      status
      sort
      createdAt
      updatedAt
      dateType
    }
  }
`

/**
 * __useGetKeyEventsByDocumentQuery__
 *
 * To run a query within a React component, call `useGetKeyEventsByDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeyEventsByDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeyEventsByDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetKeyEventsByDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetKeyEventsByDocumentQuery,
    GetKeyEventsByDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetKeyEventsByDocumentQuery,
    GetKeyEventsByDocumentQueryVariables
  >(GetKeyEventsByDocumentDocument, options)
}
export function useGetKeyEventsByDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKeyEventsByDocumentQuery,
    GetKeyEventsByDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetKeyEventsByDocumentQuery,
    GetKeyEventsByDocumentQueryVariables
  >(GetKeyEventsByDocumentDocument, options)
}
export type GetKeyEventsByDocumentQueryHookResult = ReturnType<
  typeof useGetKeyEventsByDocumentQuery
>
export type GetKeyEventsByDocumentLazyQueryHookResult = ReturnType<
  typeof useGetKeyEventsByDocumentLazyQuery
>
export type GetKeyEventsByDocumentQueryResult = Apollo.QueryResult<
  GetKeyEventsByDocumentQuery,
  GetKeyEventsByDocumentQueryVariables
>
export const GetOfficesDocument = gql`
  query GetOffices {
    offices {
      id
      name
    }
  }
`

/**
 * __useGetOfficesQuery__
 *
 * To run a query within a React component, call `useGetOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOfficesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOfficesQuery,
    GetOfficesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOfficesQuery, GetOfficesQueryVariables>(
    GetOfficesDocument,
    options
  )
}
export function useGetOfficesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfficesQuery,
    GetOfficesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOfficesQuery, GetOfficesQueryVariables>(
    GetOfficesDocument,
    options
  )
}
export type GetOfficesQueryHookResult = ReturnType<typeof useGetOfficesQuery>
export type GetOfficesLazyQueryHookResult = ReturnType<
  typeof useGetOfficesLazyQuery
>
export type GetOfficesQueryResult = Apollo.QueryResult<
  GetOfficesQuery,
  GetOfficesQueryVariables
>
export const GetSavedBundlesDocument = gql`
  query GetSavedBundles($bundlesStatus: BundleStatus!) {
    bundles(status: $bundlesStatus) {
      id
      customerName
      businessArea {
        name
      }
      appealType {
        name
      }
      createdAt
    }
  }
`

/**
 * __useGetSavedBundlesQuery__
 *
 * To run a query within a React component, call `useGetSavedBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedBundlesQuery({
 *   variables: {
 *      bundlesStatus: // value for 'bundlesStatus'
 *   },
 * });
 */
export function useGetSavedBundlesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSavedBundlesQuery,
    GetSavedBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSavedBundlesQuery, GetSavedBundlesQueryVariables>(
    GetSavedBundlesDocument,
    options
  )
}
export function useGetSavedBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSavedBundlesQuery,
    GetSavedBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSavedBundlesQuery,
    GetSavedBundlesQueryVariables
  >(GetSavedBundlesDocument, options)
}
export type GetSavedBundlesQueryHookResult = ReturnType<
  typeof useGetSavedBundlesQuery
>
export type GetSavedBundlesLazyQueryHookResult = ReturnType<
  typeof useGetSavedBundlesLazyQuery
>
export type GetSavedBundlesQueryResult = Apollo.QueryResult<
  GetSavedBundlesQuery,
  GetSavedBundlesQueryVariables
>
export const ValidateDrsSearchDocument = gql`
  query ValidateDrsSearch($validateDrsSearchInput: DrsSearchInput!) {
    validateDrsSearch(input: $validateDrsSearchInput) {
      documentCount
    }
  }
`

/**
 * __useValidateDrsSearchQuery__
 *
 * To run a query within a React component, call `useValidateDrsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateDrsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateDrsSearchQuery({
 *   variables: {
 *      validateDrsSearchInput: // value for 'validateDrsSearchInput'
 *   },
 * });
 */
export function useValidateDrsSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateDrsSearchQuery,
    ValidateDrsSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ValidateDrsSearchQuery,
    ValidateDrsSearchQueryVariables
  >(ValidateDrsSearchDocument, options)
}
export function useValidateDrsSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateDrsSearchQuery,
    ValidateDrsSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ValidateDrsSearchQuery,
    ValidateDrsSearchQueryVariables
  >(ValidateDrsSearchDocument, options)
}
export type ValidateDrsSearchQueryHookResult = ReturnType<
  typeof useValidateDrsSearchQuery
>
export type ValidateDrsSearchLazyQueryHookResult = ReturnType<
  typeof useValidateDrsSearchLazyQuery
>
export type ValidateDrsSearchQueryResult = Apollo.QueryResult<
  ValidateDrsSearchQuery,
  ValidateDrsSearchQueryVariables
>
export const namedOperations = {
  Query: {
    AppealTypesByBusinessArea: 'AppealTypesByBusinessArea',
    GetBundleDocumentsByBundleId: 'GetBundleDocumentsByBundleId',
    GetBundleDocumentsStatusByBundleId: 'GetBundleDocumentsStatusByBundleId',
    GetBundleInformation: 'GetBundleInformation',
    GetBusinessAreas: 'GetBusinessAreas',
    GetCompletedBundles: 'GetCompletedBundles',
    GetCurrentUser: 'GetCurrentUser',
    GetDocument: 'GetDocument',
    GetDossiersByBundleId: 'GetDossiersByBundleId',
    GetDossiersDrsUploadStatus: 'GetDossiersDrsUploadStatus',
    GetDossiersWithDrsDocumentIdentifier:
      'GetDossiersWithDrsDocumentIdentifier',
    GetKeyEventsByDocument: 'GetKeyEventsByDocument',
    GetOffices: 'GetOffices',
    GetSavedBundles: 'GetSavedBundles',
    ValidateDrsSearch: 'ValidateDrsSearch'
  },
  Mutation: {
    AddLocalDocumentsToBundle: 'AddLocalDocumentsToBundle',
    CompleteBundle: 'CompleteBundle',
    CreateBundle: 'CreateBundle',
    CreateDossiers: 'CreateDossiers',
    CreateKeyEvent: 'CreateKeyEvent',
    DeleteBundle: 'DeleteBundle',
    DeleteDocument: 'DeleteDocument',
    DeleteKeyEvent: 'DeleteKeyEvent',
    MoveDocumentAfter: 'MoveDocumentAfter',
    MoveKeyEventAfter: 'MoveKeyEventAfter',
    ResetDocument: 'ResetDocument',
    UpdateDocument: 'UpdateDocument',
    UpdateKeyEvent: 'UpdateKeyEvent',
    UpdateUserOffice: 'UpdateUserOffice'
  }
}
